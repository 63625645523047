import Uri from 'urijs';

import { markPerformanceAPI, measurePerformanceAPI } from '../analytics-library/entry';
import getUrl from '../hawklinks/browser/getUrl';
import Merchant from '../hawklinks/merchants/BaseMerchant';
import AmznReset from '../hawklinks/resets/AmznReset';
import AWReset from '../hawklinks/resets/AWReset';
import CJReset from '../hawklinks/resets/CJReset';
import DefaultReset from '../hawklinks/resets/DefaultReset';
import EbayReset from '../hawklinks/resets/EbayReset';
import ExpressVPNLegacyReset from '../hawklinks/resets/ExpressVPNLegacyReset';
import GeoriotReset from '../hawklinks/resets/GeoriotReset';
import LSReset from '../hawklinks/resets/LSReset';
import M101Reset from '../hawklinks/resets/M101Reset';
import PartnerAdsReset from '../hawklinks/resets/PartnerAdsReset';
import PHGReset from '../hawklinks/resets/PHGReset';
import SLReset from '../hawklinks/resets/SLReset';
import { Site } from '../types/Site';

import { isAlreadyAffiliatedWithPlaceholderTracking } from './isAlreadyAffiliated';
import Merchants, { MerchantDomain } from './merchants';

export interface BaseReset {
  getResetUrl: (url: string) => string | null | undefined;
}

export interface ProcessedLink {
  url: string;
  rewritten: boolean;
  merchant: Merchant | null;
  source: string;
}

class HawkLinks {
  private domain: string;

  private georiotTSID: string | undefined;

  private merchants: MerchantDomain[];

  private merchantEntities: Record<string, Merchant[]>;

  private site: Site;

  private resets: BaseReset[];

  constructor(
    domain: string,
    georiotTSID: string | undefined,
    merchants: MerchantDomain[],
    site: Site,
  ) {
    this.domain = domain;
    this.georiotTSID = georiotTSID;
    this.merchants = merchants;
    this.merchantEntities = {};
    this.site = site;
    this.resets = [
      new DefaultReset(site), // If a Hawk-like custom tracking is available, only reset that and assume the link affiliated
      new CJReset(),
      new AWReset(),
      new AmznReset(),
      new GeoriotReset(),
      new ExpressVPNLegacyReset(),
      new LSReset(),
      new LSReset('linksynergy.walmart.com'), // Reset legacy LS Walmart links
      new SLReset(),
      new PartnerAdsReset(),
      new M101Reset(),
      new PHGReset(),
      new EbayReset(),
    ];
  }

  private isElementRewritable = (el: HTMLAnchorElement): boolean => {
    if (el.getAttribute('data-hl-processed') && !el.getAttribute('data-placeholder-url')) {
      return false; // Link has already been processed
    }

    // skip already parsed links
    if (el.classList.contains('hawk-link-parsed')) {
      return false;
    }

    // skip links with empty href (either not set, or empty string
    if (!el.href) {
      return false;
    }

    // skip anchor links
    if (el.href.indexOf('#') === 0) {
      return false;
    }

    if (el.getAttribute('data-no-affiliate-tracking')) {
      return false;
    }

    // Skip links to the same domain. Ensure we don't skip links like https://example.com/blah?ref=techradar.com
    const elUri = new Uri(el.href);
    const elDomain = elUri.domain();
    if (elDomain.indexOf(this.domain) !== -1) {
      return false;
    }

    return true;
  };

  public processElement = (el: HTMLAnchorElement): ProcessedLink => {
    if (!this.isElementRewritable(el)) {
      return {
        rewritten: false,
        url: el.href,
        merchant: null,
        source: 'hawklinks',
      };
    }

    // If the link is rewritten serverside, use the rewritten url without tracking
    // This skips the client side processing (resetting the link, matching a merchant etc.)
    // Note: this also matches already rewritten skimlinks links
    const placeholderTrackingUrl = el.getAttribute('data-placeholder-url');
    if (placeholderTrackingUrl) {
      el.href = placeholderTrackingUrl;
      return {
        rewritten: false,
        url: el.href,
        merchant: null,
        source: 'hawklinks',
      };
    }

    let linkUrl = getUrl(el);

    // Ampersand fix
    linkUrl = linkUrl.replace(/&(amp;)+/g, '&');
    if (linkUrl !== el.href) {
      el.href = linkUrl;
    }

    markPerformanceAPI('Link Rewrite started', { detail: 'HAWKLINKS' });
    const result = this.processLink(linkUrl);
    markPerformanceAPI('Link Rewrite completed', { detail: 'HAWKLINKS' });

    measurePerformanceAPI('Time between link rewriting started to completed', {
      start: 'Link Rewrite started',
      end: 'Link Rewrite completed',
      detail: `HAWKLINKS`,
    });

    // Set the value if the link has changed or the data-url is not present yet (custom tracking or whole link was rewritten)
    if (result.url !== linkUrl || !el.getAttribute('data-url')) {
      el.setAttribute('data-url', linkUrl);
      el.href = result.url;
    }
    // Set custom dimensions if a merchant is available
    if (result.merchant) {
      if (result.merchant.name) {
        el.setAttribute('data-merchant-name', result.merchant.name);
      }
      if (result.merchant.id) {
        el.setAttribute('data-merchant-id', `${result.merchant.id}`);
      }
      if (result.merchant.url) {
        el.setAttribute('data-merchant-url', result.merchant.url);
      }
      if (result.merchant.network) {
        el.setAttribute('data-merchant-network', result.merchant.network);
      }
    }

    if (result.rewritten) {
      // Ensure on-the-fly rewrite is applied, as well as GA:
      // Set up AffClick if the link is HawkLink. That includes two options:
      //  1) The link has just been rewritten
      //  2) This is a manual link designed for HawkLinks to attach the tracking to it
      el.setAttribute('data-hl-processed', 'hawklinks');
    }

    return result;
  };

  /**
   * Return an object of rewriting information that can be used in the browser or on the server
   */
  public processLink = (url: string): ProcessedLink => {
    // Try to reset the URL - the first successful reset stops the process
    // Default reset (custom tracking only) is the first, so if the link contains "trd-12345",
    // only that will be reset (to "trd-custom-tracking")
    const resetUrl =
      this.resets.reduce<string | null | undefined>(
        (resultUrl: string | null | undefined, reset: BaseReset) =>
          resultUrl || reset.getResetUrl(url),
        null,
      ) || url;

    // Already pre-affiliated links are considered to be rewritten, don't process them further
    if (isAlreadyAffiliatedWithPlaceholderTracking(resetUrl)) {
      return {
        url: resetUrl,
        merchant: null,
        rewritten: true,
        source: 'hawklinks',
      };
    }

    const uriObject = new Uri(resetUrl);
    const linkDomain = uriObject.domain().toLocaleLowerCase();

    // Only set the merchant entities up when we first need them
    // This avoids us unnecessarily setting them up if we don't need to rewrite any links
    // (e.g. they were all rewritten serverside)
    if (Object.keys(this.merchantEntities).length === 0) {
      this.merchantEntities = new Merchants().getMerchants(
        this.georiotTSID,
        this.merchants,
        this.site,
      );
    }

    if (this.merchantEntities[linkDomain]) {
      // Try dynamically rewrite the link using the first matching merchant from the domain bucket
      // (usually 1-item array)

      // for (const merchant of this.merchantEntities[linkDomain]) {
      const urlObject = new Uri(resetUrl);
      const hostname = urlObject.hostname().replace('www.', '');
      const path = urlObject.path();
      const merchant = this.merchantEntities[linkDomain].find((merchant) => {
        if (merchant.isMatch(hostname, path)) {
          const newUrl = merchant.rewrite(resetUrl);

          // TODO we probably don't need to check for link being different
          //  as long as there was match above
          if (newUrl !== resetUrl || merchant.treatUnchangedLinkAsProcessed()) {
            return merchant;
          }
        }
        return null;
      });

      if (merchant) {
        // Link was rewritten by a HL merchant
        return {
          url: merchant.rewrite(resetUrl),
          merchant,
          rewritten: true,
          source: 'hawklinks',
        };
      }
    }

    // Link was not rewritten
    return {
      url,
      merchant: null,
      rewritten: false,
      source: 'hawklinks',
    };
  };
}

export default HawkLinks;
